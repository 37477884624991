import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import ILeaderboardData from './ILeaderBoardData';
import { ROLE } from '../../common/constants/displayEventStatus';
import UserLeaderBoardModal from '../../components/LeaderBoardConfirmationModal/UserLeaderBoardModal';
import TableComponent from '../../components/LeaderBoardDetails/LeaderBoardTable';
import TopThreeScores from '../../components/TopThreeScores/TopThreeScores';
import { useAppSelector } from '../../redux/store';
import EventService from '../../services/EventService';
import Spinner from '../../shared/Loading-Spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';

function getTopNRanks(data: ILeaderboardData[], n: number): ILeaderboardData[] {
  const sortedData = data.sort((a, b) => a.rank - b.rank);
  const topNRanks = sortedData.slice(0, n);
  return topNRanks;
}

function LeadBoardDetails() {
  const [data, setData] = useState<ILeaderboardData[]>([]);
  const [participantData, setParticipantData] = useState<ILeaderboardData[]>([]);
  const { eventId } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [isLeaderBoardPublished, setIsLeaderBoardPublished] = useState(false);
  const [tableRole, setTableRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPodium, setIsLoadingPodium] = useState(true);
  const [fileName, setFileName] = useState('');
  const user = useLocation().state || tableRole;
  const { isOrganizer, isJudge } = useAppSelector(
    (state) => state.rootReducer.EventRoleReducer.roles,
  );

  const enableLeaderboardForEvent = useCallback(() => {
    setModalShow(true);
  }, []);

  const fetchData = useCallback(async () => {
    if (isOrganizer) {
      setTableRole(ROLE.ORGANIZER);
    } else if (isJudge) {
      setTableRole(ROLE.JUDGE);
    } else {
      setTableRole(ROLE.USER);
    }
    await EventService.getEventLeaderBoard(eventId)
      .then((response) => {
        const responseData: ILeaderboardData[] = response.data.message;

        setParticipantData(responseData);
        const topNRanks = getTopNRanks(responseData, 3);
        setData(topNRanks);
      })
      .finally(() => {
        setIsLoadingPodium(false);
      });

    const responseJudge = await EventService.getJudgementDataById(eventId);

    const initialDate = new Date(responseJudge.data.message.judgementFormData[0]?.startDate);
    const finalDate = new Date(responseJudge.data.message.judgementFormData[0]?.endDate);
    const presentDate = new Date();

    if (presentDate <= finalDate && presentDate >= initialDate) {
      setIsPublished(true);
    }
  }, []);

  const headers = useMemo(() => {
    return [
      { label: 'Participant', key: 'userName' },
      { label: 'Email', key: 'userEmail' },
      { label: 'Rank', key: 'rank' },
      { label: 'Total Score', key: 'totalScore' },
    ];
  }, []);

  const columns: any = useMemo(() => {
    {
      return [
        {
          Header: 'Rank',
          accessor: 'rank',
          Cell: (cell: any) => {
            const { rank } = cell.row.original;
            return cell.row.original.rank === 1 ? (
              <i className="fas first-medal"><FontAwesomeIcon icon={faMedal} /></i>
            ) : cell.row.original.rank === 2 ? (
              <i className="fas second-medal" ><FontAwesomeIcon icon={faMedal} /></i>
            ) : cell.row.original.rank === 3 ? (
              <i className="fas third-medal" ><FontAwesomeIcon icon={faMedal} /></i>
            ) : (
              <span>{rank}</span>
            );
          },
        },
        {
          Header: 'Participant',
          accessor: 'userName',
        },
        {
          Header: 'Email',
          accessor: 'userEmail',
          Cell: (cell: any) => <div className="lowercase">{cell.value}</div>,
        },
        {
          Header: 'Points',
          accessor: 'totalScore',
        },
      ];
    }
  }, []);

  const userColumns: any = useMemo(() => {
    {
      return [
        {
          Header: 'Rank',
          accessor: 'rank',
          Cell: (cell: any) => {
            const { rank } = cell.row.original;
            return cell.row.original.rank === 1 ? (
              <span data-bs-toggle="tooltip" data-bs-placement="left" title="1st">
                <i className="fas first-medal" ><FontAwesomeIcon icon={faMedal} /></i>
              </span>
            ) : cell.row.original.rank === 2 ? (
              <i className="fas second-medal"><FontAwesomeIcon icon={faMedal} /></i>
            ) : cell.row.original.rank === 3 ? (
              <i className="fas third-medal" ><FontAwesomeIcon icon={faMedal} /></i>
            ) : (
              <span>{rank}</span>
            );
          },
        },
        {
          Header: 'Participant',
          accessor: 'userName',
        },
        {
          Header: 'Email',
          accessor: 'userEmail',
        },
      ];
    }
  }, []);

  useEffect(() => {
    fetchData();
    EventService.getEvent(eventId).then((response: any) => {
      setIsLoading(false);
      setFileName(`${response.data.message.name}-Leaderboard File.csv`);
      setIsLeaderBoardPublished(response.data.message.isLeaderboardEnabled);
      const todayDate = new Date();
      const judgeEndDate = new Date(response.data.message.judgementEndDate);
      if (judgeEndDate <= todayDate) {
        setIsPublished(true);
      }
    });
  }, []);
  return (
    <>
      <UserLeaderBoardModal show={modalShow} onHide={() => setModalShow(false)} eventId={eventId} />
      <div className="p-2" />
      <div className="leader-board">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="d-flex my-2 justify-content-between">
              {isOrganizer && (
                <>
                  <p>
                    Note : When this leaderboard is published to participants, points will not be
                    shown
                  </p>
                  {isPublished && !isLeaderBoardPublished && (
                    <button
                      type="button"
                      className="btn btn-outline publish justify-content-end"
                      onClick={() => enableLeaderboardForEvent()}
                      disabled={isLeaderBoardPublished}
                    >
                      <i className="bi bi-clipboard2-check pe-1" />
                      Publish Leader Board
                    </button>
                  )}

                  {isLeaderBoardPublished && (
                    <button
                      type="button"
                      className="btn btn-outline publish justify-content-end"
                      disabled={isLeaderBoardPublished}
                    >
                      <i className="bi bi-check pe-1" />
                      Published Leader Board
                    </button>
                  )}
                </>
              )}
            </div>

            <div className="row">
              <div className="col-xs-12 col-lg-7 my-2 podium-width" style={{ alignSelf: 'center' }}>
                {}
                {user === ROLE.JUDGE ||
                  (isOrganizer && !isLoadingPodium && (
                    <TopThreeScores topRanks={data} showScores={true} />
                  ))}
                {user === ROLE.USER && !isLoadingPodium && <TopThreeScores topRanks={data} />}
              </div>
              <div className="col-xs-12 col-lg-5 my-2 overflow-x-scroll table-width">
                {user === ROLE.JUDGE || isOrganizer ? (
                  <TableComponent
                    data={participantData}
                    columns={columns}
                    headers={headers}
                    filename={fileName}
                  />
                ) : null}

                {user === ROLE.USER && (
                  <TableComponent
                    data={participantData}
                    columns={userColumns}
                    headers={headers}
                    filename={fileName}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default LeadBoardDetails;
